<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="gfGFmixCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import GFMixNutsAndRaisin from "../assets/GOODFOOD/GFmix/mix-nutsandraisin.jpg";
import GFMixNuts from "../assets/GOODFOOD/GFmix/mix-nuts.jpg";
import GFMixSweet from "../assets/GOODFOOD/GFmix/mix-sweet.jpg";
import GFMixTropicana from "../assets/GOODFOOD/GFmix/mix-tropicana.jpg";
//import GFMixWine from "../assets/GOODFOOD/GFmix/mix-present.jpg";

export default {
  name: "GoodFoodMix",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Смеси орехов, сухофруктов и ягод",
      gfGFmixCards: [
        {
          picture: GFMixNutsAndRaisin,
          text: "GOOD FOOD Смесь жареных орехов и изюма 130 г",
          id: 1,
          description:
            "Состав: изюм (виноград сушеный без косточек, масло подсолнечное), жареные ядра орехов: кешью, фундука, миндаля в изменяемых соотношениях.<br>Продукт может содержать следы кунжута, арахиса и других орехов.<br>Пищевая ценность в 100 г: Белки 17,3 г, Жиры 20,8 г, Углеводы 44,8 г<br>Энергетическая ценность 435,6 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFMixNuts,
          text: "GOOD FOOD Смесь ореховая 130 г",
          id: 2,
          description:
            "Состав: изюм (виноград сушеный без косточки, подсолнечное масло, консервант Е220), сушеные ядра миндаля, фундука, кешью, бразильского ореха, в изменяемых соотношениях.<br>Продукт может содержать фрагменты скорлупы орехов, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 10 г, Жиры 39 г, Углеводы 36 г<br>Энергетическая ценность 535 Ккал.Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9761-004-60458573-12<br>Страна изготовитель: Россия",
        },
        {
          picture: GFMixSweet,
          text: "GOOD FOOD Смесь сладкая 130 г",
          id: 3,
          description:
            "Состав: ананас сушеный (ананас, сахар, регулятор кислотности лимонная кислота, консервант Е220, красители Е124, Е122, Е133, Е102, Е110), изюм (виноград сушеный без косточки, подсолнечное масло), сушеные ядра миндаля, фундука, кешью, кубики кокоса (кокос, сахар, регулятор кислотности лимонная кислота, консервант Е220), ядра бразильского ореха сушеные, папайя сушеная (папайя, сахар, регулятор кислотности лимонная кислота, консервант Е220) в изменяемых соотношениях.<br>Содержит красители, которые могут оказывать отрицательное влияние на активность и внимание детей.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 8,5 г, Жиры 30 г, Углеводы 31 г<br>Энергетическая ценность 428 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GFMixTropicana,
          text: "GOOD FOOD Тропикана 130 г",
          id: 4,
          description:
            "Состав: ананасы сушеные (ананас, сахар, регулятор кислотности – лимонная кислота, консервант Е220), папайя сушеная (папайя, сахар, регулятор кислотности – лимонная кислота, консервант Е220), изюм (виноград сушеный без косточек, масло подсолнечное), ядра арахиса жареные, жареные ядра орехов: фундука, кешью, миндаля в изменяемых соотношениях.<br>Продукт может содержать следы кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 10,9 г, Жиры 23 г, Углеводы 51 г<br>Энергетическая ценность 454,6 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        // {
        //   picture: GFMixWine,
        //   text: "GOOD FOOD SPECIAL Орехово-фруктовая смесь к белому вину 300 г",
        //   id: 5,
        //   description:
        //     "Состав:манго сушеное (манго, сахар, регулятор кислотности лимонная кислота, консервант Е220), ананас сушеный ( ананас, сахар, регулятор кислотности лимонная кислота, консервант Е220), ядра ореха пекан сушеные, ядра кешью сушеные, белый шоколад (сахар, какао масло, цельное сухое молоко, эмульгатор соевый лецитин, ароматизатор ванилин).  В белом шоколаде (дропсы) - содержание какао масла не менее 20%, массовая доля сухого обезжиренного остатка молока  не менее 14%, массовая доля молочного жира не менее 3,5%.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 7,3 г, Жиры 30,0 г, Углеводы 49,0 г, Пищевые волокна 6,0 г<br>Энергетическая ценность 495,2 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        // },
      ],
    };
  },
};
</script>
